<template>
  <body>
    <header>
      <Header />
    </header>
    <main>
      <div id="main-content">
        <router-view />
      </div> 
    </main>
  </body>
</template>

<script>
import Header from "./components/Header.vue";
import Main from "./views/Home.vue";
const axios = require("axios");


  export default {
    name: "App",
    components: {
      Header,
      Main
    }
  }
</script>


<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  * {
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
  }
  
  #main-content {
    width: 900px;
  }
  
  @media screen and (max-width: 1000px){
    #main-content {
      width: 100%;
    }
  }

  main {
    display: flex;
    justify-content: center;
  }
</style>