<template>
  <div id="main-content">
    <div id="main-container-me">
      <figure>
        <img src="../../public/imgs/image-logo-x.png" alt="" class="main-container-me-image">
        <figcaption>
          <h1>Frases 💭</h1>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Inventore esse laudantium distinctio voluptatibus suscipit! Omnis, velit quas, consectetur modi est veniam libero nostrum qui labore perferendis placeat repudiandae atque repellendus.</p>
        </figcaption>
      </figure>
    </div>

    <div id="main-containers-content">
      <div class="main-containers">
        <p>{{ user.frases[1].frase }}</p>
      </div>
      <div class="main-containers">
        <p>{{ user.frases[2].frase }}</p>
       </div>
      <div class="main-containers">
        <p>{{ user.frases[3].frase }}</p>
      </div>
      <!-- <div class="main-containers">
        <p>{{ user.frases[4].frase }}</p>
      </div> -->
      <div class="main-containers">
        <p>{{ user.frases[5].frase }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios"
export default {
  name: 'Frases',
  data() {
    return {
      user: {}
    }
  },
  created() {
    this.getUser()
  },
  methods: {
      getUser() {
       axios
         .get("https://api-frases.onrender.com/frases")
         .then((res) => {
           this.user = res.data;
         })
         .catch((error) => {
           console.log(error);
         });
      },
    }
  }
</script>


<style scoped> 
   /* #main-containers-content {
    display: flex;
   } */
  .main-containers {
    border: 1px solid white;
    height: 200px;
    padding: 30px;
  }

  #main-container-me {
    widows: 100%;
  }

  #main-container-me figure {
    display: flex;
    margin: 40px 0;
  }

  #main-container-me figcaption {
    padding: 30px;
  }

  .main-container-me-image {
    border-radius: 100%;
    height: 240px;
  }

  @media screen and (max-width: 1000px){
    #main-containers-content {
      display: flex;
      flex-direction: column;
    }
    .main-containers {
      width: 80%;
      padding: 40px;
      margin: 30px;
      margin: auto;
    }
  }
  
  @media screen and (max-width: 700px){
    .main-containers {
      width: 60%;
      padding: 60px;
      height: 100px;
    }

    #main-container-me figure {
      flex-direction: column;
    }

    .main-container-me-image {
      width: 50%;
      margin: auto;
    }
    
  }
</style>