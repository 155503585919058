<template>
  <div id="main-content">
    <div id="main-container-me">
      <figure>
        <img src="../../public/imgs/image-logo-x.png" alt="" class="main-container-me-image">
        <figcaption>
          <h1>Need You</h1>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Inventore esse laudantium distinctio voluptatibus suscipit! Omnis, velit quas, consectetur modi est veniam libero nostrum qui labore perferendis placeat repudiandae atque repellendus.</p>
        </figcaption>
      </figure>
    </div>

    <div id="containers-itens">
      <div class="container-item container-item-image-frase">
        <router-link to="/about">
          <h1>FRASES</h1>
        </router-link>
      </div>
      <div class="container-item">

      </div>
      <div class="container-item">
        
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Main',
  data() {
    return {
      
    }
  }
}
</script>


<style scoped> 

  #main-container-me {
    width: 100%;
  }

  #main-container-me figure {
    display: flex;
    margin: 40px 0;
  }

  #main-container-me figcaption {
    padding: 30px;
  }

  .main-container-me-image {
    border-radius: 100%;
    height: 240px;
  }

  #containers-itens {
    display: flex;
    margin: 40px 0;
  }

  .container-item {
    border: 1px solid blue;
    width: 300px;
    height: 160px;
    margin: 10px;
    cursor: pointer;
  }

  .container-item:hover {
    box-shadow: 5px 5px 10px #046d9a;
  }

  .container-item-image-frase {
    background: url("../../public/imgs/image-container-frase.jpg") no-repeat center;
    background-size: 100%;
  }
  .container-item-image-frase a {
    text-decoration: none;
    color: white;
  }
  .container-item-image-frase h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 800px){
    #main-container-me figure {
      flex-direction: column;  
    }

    .main-container-me-image {
      width: 60%;
      height: 60%;
      margin: auto;
    }
  
    #containers-itens {
      display: flex;
      flex-direction: column;
    }

    .container-item {
      margin: 2px auto;
      width: 80%;
    }
  }

  @media screen and (max-width: 400px){
    .container-item {
      width: 95%;
    }
  }
</style>