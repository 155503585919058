<template>
    <div id="menu">
        <nav>
            <router-link to="/" id="header-container-logo">
                <img src="../../public/imgs/image-logo-x.png" alt="Logo Need You" id="header-logo">
            </router-link>
            <router-link to="/">Início</router-link> |
            <router-link to="/frases">Frases</router-link> |
            <router-link to="/about">Previsão</router-link> |
            <router-link to="/about">Contato</router-link>
         
        </nav>
    </div>
</template>


<script>
    export default {
        name: 'Header'
    }
</script>


<style>

    #menu nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    #menu nav a {
        text-decoration: none;
        color: white;
    }

    #header-container-logo {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #header-logo {
        height: 100px;
    }

    @media screen and (max-width: 800px){
        #header-container-logo {
            width: 100px;
        }    
    }
</style>